
import { CountryAPI } from '@/api/countries.api'
import { Country } from '@/models/countries/classes/Country'
import SelectButton from 'primevue/selectbutton'
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'

export default defineComponent({
  name: 'CountrySelector',
  components: { SelectButton },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Array as PropType<Array<string>>,
      required: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    optionValue: {
      type: String,
      default: 'code'
    }
  },
  setup (props, { emit }) {
    const countries = ref<Array<Country>>([])

    onMounted(async () => {
      try {
        countries.value = await CountryAPI.findAll()
      } catch (e) {
        console.error(e)
      }
    })

    const selectedCountries = computed({
      get () {
        return props.modelValue
      },
      set (countries: Array<string>) {
        emit('update:modelValue', countries)
      }
    })

    return {
      countries,
      selectedCountries
    }
  }
})
