import { Api } from '@/api/api'
import { SiteEvent, WwwImage } from '@/models/www/SiteEvent'
import { SvgProcessingOptions } from '@/models/www/SvgUploader'

const baseURL = process.env.ACADEMIC_API_ENDPOINT

async function getWwwEvents(): Promise<SiteEvent[]> {
  return Api.get<SiteEvent[]>(`${baseURL}/v1/site-events`)
}

async function getWwwEventById(id: string): Promise<SiteEvent | null> {
  return Api.get<SiteEvent | null>(`${baseURL}/v1/site-events/${id}`)
}

async function saveWwwEvent(siteEvent: SiteEvent): Promise<SiteEvent> {
  if (siteEvent.id) {
    return Api.patch<SiteEvent>(
      `${baseURL}/v1/site-events/${siteEvent.id}`,
      siteEvent
    )
  }

  return Api.post<SiteEvent>(`${baseURL}/v1/site-events`, siteEvent)
}

async function saveWwwImage(file: File): Promise<WwwImage> {
  const backofficeAPI = process.env.API_ENDPOINT
  const formData = new FormData()
  formData.append('file', file)
  return Api.post<WwwImage>(
    `${backofficeAPI}/private/upload-image`,
    formData,
    {},
    { headers: { 'Content-Type': 'multipart/form-data' } }
  )
}
async function uploadSvg(
  file: File,
  processingOptions?: SvgProcessingOptions
): Promise<WwwImage> {
  const formData = new FormData()
  formData.append('file', file)

  return Api.post<WwwImage>(`${baseURL}/v0/upload-svg`, formData, {
    ...(processingOptions || {})
  })
}

export { getWwwEvents, getWwwEventById, saveWwwEvent, saveWwwImage, uploadSvg }
