import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectButton = _resolveComponent("SelectButton")!

  return (_openBlock(), _createBlock(_component_SelectButton, {
    modelValue: _ctx.selectedCountries,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedCountries = $event)),
    options: _ctx.countries,
    optionValue: _ctx.optionValue,
    optionLabel: "code",
    multiple: _ctx.multiple
  }, null, 8, ["modelValue", "options", "optionValue", "multiple"]))
}